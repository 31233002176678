<template>
  <ErrorComponent errorCode="404" />
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import ErrorComponent from '../../components/ErrorComponent.vue'

export default defineComponent({
  created() {
    document.body.classList.add('gray-bg')
  },
  components: {
    ErrorComponent
  }
})
</script>
<style>
h3 {
  padding-bottom: 0 !important;
  font-weight: 700 !important;
}
</style>
