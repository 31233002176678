<template>
  <div class="error-wrapper center-flex">
    <img
      class="animated fadeInDown"
      :src="getErrorImage"
      alt="Error image"
      :title="computedTitle" />
    <div class="error-text center-flex animated fadeInUp">
      <h3>{{ computedTitle }}</h3>
      <p v-if="computedDescription">{{ computedDescription }}</p>
      <router-link to="/">Return to backbar.app</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

interface ErrorData {
  title: string
  description?: string
}

const errorData: Record<string, ErrorData> = {
  404: {
    title: 'Sorry, this page could not be found.'
  },
  500: {
    title: 'Server error',
    description: 'An error has occurred, and we are working to fix the problem.'
  },
  503: {
    title: 'Service unavailable',
    description: 'The server is temporarily unavailable due to maintenance. Try again later. '
  }
}

export default defineComponent({
  name: 'ErrorComponent',
  props: {
    errorCode: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const computedTitle = computed(() => {
      return errorData[props.errorCode]?.title
    })

    const computedDescription = computed(() => {
      return errorData[props.errorCode]?.description
    })

    const getErrorImage = computed(() => {
      return new URL(`../assets/images/errorImages/${props.errorCode}.svg`, import.meta.url).href
    })

    return {
      computedTitle,
      computedDescription,
      getErrorImage
    }
  }
})
</script>

<style scoped>
.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.error-wrapper {
  height: 100vh;
  width: 100vw;
  gap: 1rem;
  text-align: center;
}

.error-text {
  gap: 0.25rem;
}

a {
  color: #0074dd;
  margin-top: 0.25rem;
}

@media only screen and (max-width: 600px) {
  img {
    max-width: 90%;
  }
}
</style>
