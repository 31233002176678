import type { FeatureFlags } from '@/stores/feature-flags'
import moment from 'moment'
// Get Javascript dates from flat picker result string
export function datesFromFlatPicker(flatPickerStr) {
  const regex = new RegExp(/ to /, 'm')
  let startDate
  let endDate

  const index = flatPickerStr.search(regex)
  if (index > -1) {
    // a single date selected?
    startDate = flatPickerStr.substring(0, index)
    endDate = flatPickerStr.substring(index + 4, flatPickerStr.length)
  } else {
    startDate = flatPickerStr
    endDate = flatPickerStr
  }

  return {
    from: new Date(startDate),
    to: new Date(endDate)
  }
}

export function getPickerTimeRange(timeRange: string) {
  let start_time = 0
  let today_start = new Date(new Date().setHours(0, 0, 0, 0))
  let end_time = Math.floor(Date.now() / 1000)
  if (timeRange == 'yesterday') {
    start_time = Math.floor(today_start.setDate(today_start.getDate() - 1) / 1000)
    today_start = new Date(new Date().setHours(0, 0, 0, 0))
    end_time = Math.floor(today_start.getTime() / 1000)
  } else if (timeRange === 'last_7days') {
    start_time = Math.floor(today_start.setDate(today_start.getDate() - 7) / 1000)
    today_start = new Date(new Date().setHours(0, 0, 0, 0))
    end_time = Math.floor(today_start.getTime() / 1000)
  } else if (timeRange === 'thismonth') {
    start_time = Math.floor(today_start.setDate(1) / 1000)
  } else if (timeRange === 'lastmonth') {
    const last_month_start = new Date(today_start.setMonth(today_start.getMonth() - 1)).setDate(1)
    start_time = Math.floor(last_month_start / 1000)
    today_start = new Date(new Date().setHours(0, 0, 0, 0)) // this is important as today_start changed ^^
    const last_month_end = new Date(today_start.setDate(1)).setDate(1)
    end_time = last_month_end / 1000
  } else if (timeRange === '3months') {
    start_time = Math.floor(today_start.setMonth(today_start.getMonth() - 3) / 1000)
  } else if (timeRange === '6months') {
    start_time = Math.floor(today_start.setMonth(today_start.getMonth() - 6) / 1000)
  } else if (timeRange === '1year') {
    start_time = Math.floor(today_start.setMonth(today_start.getMonth() - 12) / 1000)
  } else if (timeRange === 'yeartodate') {
    today_start.setMonth(0)
    today_start.setDate(1)
    start_time = Math.floor(new Date(today_start).getTime() / 1000)
  } else if (timeRange === '2years') {
    start_time = Math.floor(today_start.setMonth(today_start.getMonth() - 24) / 1000)
  } else if (timeRange === 'max') {
    start_time = 0
  }
  return {
    start_time,
    end_time
  }
}

export function getPickerTimeRanges(timeRange: string, now: Date, venueCreateDate: Date) {
  const dateRanges: { start_time: number; end_time: number }[] = []
  let iterations = 0
  switch (timeRange) {
    case '6months':
      iterations = 6
      break
    case '1year':
      iterations = 12
      break
    case 'yeartodate':
      const currentYear = now.getFullYear()
      while (now.getFullYear() === currentYear) {
        dateRanges.push({
          start_time: Math.floor(new Date(now.getFullYear(), now.getMonth(), 1).getTime() / 1000),
          end_time: Math.floor(new Date(now.getFullYear(), now.getMonth() + 1, 0).getTime() / 1000)
        })
        now.setMonth(now.getMonth() - 1)
      }
      return dateRanges
    case '2years':
      iterations = 24
      break
    case 'max':
      const then = new Date(venueCreateDate.getFullYear(), venueCreateDate.getMonth())
      while (then <= now) {
        dateRanges.push({
          start_time: Math.floor(new Date(now.getFullYear(), now.getMonth(), 1).getTime() / 1000),
          end_time: Math.floor(new Date(now.getFullYear(), now.getMonth() + 1, 0).getTime() / 1000)
        })
        now.setMonth(now.getMonth() - 1)
      }
      return dateRanges
    default:
      return dateRanges
  }
  for (let i = 0; i < iterations; i++) {
    dateRanges.push({
      start_time: Math.floor(new Date(now.getFullYear(), now.getMonth(), 1).getTime() / 1000),
      end_time: Math.floor(new Date(now.getFullYear(), now.getMonth() + 1, 0).getTime() / 1000)
    })
    now.setMonth(now.getMonth() - 1)
  }
  return dateRanges
}

export function isOtcItem(featureFlags?: FeatureFlags, item?: any) {
  if (featureFlags?.otcPricing) {
    // If uom_unit of uom_case has been set, it's an otc item so the user can't edit certain properties otherwise the price pulled from sales API will be inaccurate
    return !!item?.uom_unit || !!item?.uom_case
  }
  return false
}

/**
 * A helper for adding an 's' at the end of a word, if necessary.

 * @returns {string} - Returns the original word if count is 1, else it appends an 's' at the end of the word.
 */
export function pluralizeWord(word: string, count: number): string {
  if (count === 1) return word
  if (
    word.endsWith('s') ||
    word.endsWith('x') ||
    word.endsWith('z') ||
    word.endsWith('sh') ||
    word.endsWith('ch')
  ) {
    return word + 'es'
  }
  return word + 's'
}

export const convert24HoursTo12Hours = (inputTime: number) => {
  return moment(inputTime, 'HH:mm:ss').format('h:mm A')
}

export const convertDateToLongDateTimeFormat = (inputDate: number) => {
  const date = moment.unix(inputDate).format('ddd, MMM D, YYYY, h:mmA')
  return date
}

export const convertDateToLongDateFormat = (inputDate: number) => {
  return moment.unix(inputDate).format('ddd, MMM D, YYYY')
}

export const getClosestDayFromWeekDays = (dates) => {
  const todayIndex = moment().isoWeekday()

  let closestDate = null
  let closestDateDifference = Infinity

  for (const date of dates) {
    const dateIndex = moment().day(date).isoWeekday()
    const difference = (dateIndex - todayIndex + 7) % 7

    if (difference < closestDateDifference) {
      closestDate = date
      closestDateDifference = difference
    }
  }
  return closestDate
}

export const capitalizeSentenceCase = (inputText: string) => {
  if (!inputText || inputText?.length === 0 || typeof inputText !== 'string') {
    return ''
  }
  return inputText.charAt(0).toUpperCase() + inputText.slice(1)
}
